var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.init = function () {
        mena.main.loadAssets();
        // mena.main.stickyHeader();
        mena.main.mobileMenu();
        mena.main.svg4everybody();
        mena.main.heroScroll();
        mena.main.statsVideo();
        mena.main.accordions();
        mena.main.shareToggle();
        mena.main.animations();
        mena.main.logos();
        mena.main.shareBtn();
        mena.main.archiveProjects();
        mena.main.stickySocials();
        mena.main.megaMenu();
        mena.main.newsArchive();
        mena.main.langSwitcher();
    };

    mena.main.animations = function () {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.batch('[data-animation=\'fade-up\']', {
            interval: 0.1, // time window (in seconds) for batching to occur.
            //batchMax: 3, // maximum batch size (targets). Can be function-based for dynamic values
            onEnter: (batch) =>
                gsap.to(batch, {
                    opacity  : 1,
                    y        : 0,
                    stagger  : { each: 0.08 },
                    ease     : 'back.Out(4)',
                    overwrite: true,
                    duration : 1,
                }),

            // you can also define most normal ScrollTrigger values like start, end, etc.
            start: 'top 80%',
            //end: "top top",
        });
    };

    mena.main.typingText = function () {
        gsap.registerPlugin(TextPlugin);
        var titles = $('.intro__titles').val().substring(0, $('.intro__titles').val().length - 1).split('|');

        titles.forEach(function (title, index) {
            titles[index] = title.substring(1, title.length);
        });

        var mainTimeline = gsap.timeline({
            repeat: -1,
        });

        titles.forEach(function (title) {
            var timeline = gsap.timeline({
                repeat: 1,
                yoyo  : true,
                delay : 1,
            });

            timeline.to('.intro__title-dynamic', {
                duration: 3,
                text    : title,
            });

            mainTimeline.add(timeline);
        });
    };

    mena.main.svg4everybody = function () {
        svg4everybody();
    };

    mena.main.shareToggle = function () {
        if ($('.social-share').length > 0) {
            $('.social-share__close').on('click', function () {
                $('.social-share').toggleClass('active');
            });
        }
    };

    mena.main.loadAssets = function () {
        // if ($('section.gallery').length > 0) {
        //     loadjs(menaObject.assets + '/js/masonry.pkgd.min.js\'', function () {
        //         mena.main.gallery();
        //     });
        // }

        if ($('section.intro').length > 0) {
            loadjs(menaObject.assets + '/js/gsap.min.js\'', function () {
                loadjs(menaObject.assets + '/js/textplugin.min.js\'', function () {
                    loadjs(menaObject.assets + '/js/easepack.min.js\'', function () {
                        mena.main.typingText();
                    });
                });
            });
        }
    };

    mena.main.accordions = function () {
        if ($('.accordions').length > 0) {
            $('.accordions__accordion-title').on('click', function () {
                $(this).toggleClass('opened');
                $(this).closest('.accordions__accordion').find('.accordions__accordion-content').slideToggle(500);
            });
        }
    };

    mena.main.gallery = function () {
        $('.gallery__container').masonry({
            itemSelector: '.gallery__item',
            gutter      : 10,
        });
    };

    mena.main.statsVideo = function () {
        if ($('.stats__video').length > 0) {
            $('.stats__video').on('click', function () {
                $(this).find('img').css('opacity', 0);
                $(this).find('svg').css('opacity', 0);
                $(this).addClass('hidden');

                setTimeout(() => {
                    $(this).find('iframe').css('z-index', 5);
                }, 500);
            });
        }
    };

    mena.main.mailchimp = function () {
        if ($('.js-mailchimp').length > 0) {

            loadjs(menaObject.assets + '/js/mailchimp-validate.js', function () {
                window.fnames = new Array();
                window.ftypes = new Array();
                fnames[0] = 'EMAIL';
                ftypes[0] = 'email';
            });
        }
    };

    mena.main.heroScroll = function () {
        if ($('.hero .scroll-to-content, .intro__scroll-to-content').length > 0) {
            $('.hero .scroll-to-content, .intro__scroll-to-content').on('click', function () {
                var element = $(this).closest('section').next('section');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(element).offset().top - 60,
                }, 1000);
            });
        }
    };

    mena.main.debounce = function (func, wait, immediate) {

        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };

    };

    mena.main.stickyHeader = function () {

        var stickyHeader = function () {
            var header = $('#header');
            // $( '#content' ).css( "margin-top", header.outerHeight() );

            if ($(window).scrollTop() > 1) {
                header.addClass('sticky');
            } else {
                header.removeClass('sticky');
            }
        };

        window.addEventListener('load', stickyHeader);
        window.addEventListener('scroll', mena.main.debounce(stickyHeader, 150));
        window.addEventListener('resize', mena.main.debounce(stickyHeader, 150));

    };

    mena.main.mobileMenu = function () {

        var respMenu = $('.resp-menu');
        var button = $('.js-menu-toggle');
        var body = $('body');

        button.click(function (e) {
            body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        });

        /* Sliding side menu, close on swipe */
        if ($().swipe) {
            respMenu.swipe({
                swipeRight      : function (event, direction, distance, duration, fingerCount) {
                    body.removeClass('menu-open');
                },
                threshold       : 20,
                excludedElements: '',
            });
            button.swipe({
                swipeLeft       : function (event, direction, distance, duration, fingerCount) {
                    body.addClass('menu-open');
                },
                threshold       : 20,
                excludedElements: '',
            });
        }

        /* Sliding side menu submenu */
        respMenu.find('.menu-item > a').click(function (e) {
            if ($(this).attr('href') == '#') {
                e.preventDefault();
                $('.sub-menu').not($(this).siblings()).slideUp(300);
                $(this).siblings('.sub-menu').slideToggle(300);
            }
        });

        /* Adding submenu arrows to the responsive navigation */
        respMenu.find('.sub-menu').each(function () {
            $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg class="arrow-down" width="15px" height="8px"><polygon fill="#fff" points="15.002,0 7.501,7.501 0,0 "></polygon></svg></a>');
        });

        $('.submenu-button').click(function () {
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).prev('.sub-menu').slideToggle(100);
            $(this).toggleClass('rotate-arrow');
        });

    };

    mena.main.logos = function () {
        if ($('.hero-v2__logos-item').length > 0) {
            let animatedLogos = document.querySelectorAll('.hero-v2__logos-item:not(.hero-v2__logos-item--duplicate)');
            let animatedLogosWidth = 0;

            if (animatedLogos) {
                animatedLogosWidth = document.querySelector('.hero-v2__logos-wrapper').offsetWidth / 2;
            }

            document.documentElement.style.setProperty('--animateLogosWidth', `-${animatedLogosWidth}px`);
        }
    };

    mena.main.shareBtn = function () {
        $('.share-button').on('click', function () {
            $(this).siblings('.social-share').toggleClass('show');
        });
    };

    mena.main.archiveProjects = function () {
        if ($('.project-archive__filters').length > 0) {
            $(document).mouseup(function (e) {
                let filters = $('.project-archive__filters');

                if (!filters.is(e.target) && filters.has(e.target).length === 0) {
                    filters.find('.project-archive__filters-list').removeClass('active');
                }
            });

            $('.project-archive__filters').on('click', function () {
                if (!$(this).find('.project-archive__filters-list').hasClass('active')) {
                    $('.project-archive__filters-list').removeClass('active');
                    $(this).find('.project-archive__filters-list').addClass('active');
                } else {
                    $('.project-archive__filters-list').removeClass('active');
                }
            });

            $('.project-archive__filters-item').on('click', function () {
                let termName = $(this).text();
                let parent = $(this).closest('.project-archive__filters');
                $('body').trigger('click');

                if (!$(this).hasClass('active')) {
                    parent = $('.project-archive__filters-item.active').closest('.project-archive__filters');
                    let text = $(parent).find('.project-archive__filters-btn').data('label');
                    $(parent).find('.project-archive__filters-btn').text(text);

                    $('.project-archive__filters-item').removeClass('active');
                    $(this).addClass('active');
                    $(this).closest('.project-archive__filters').find('.project-archive__filters-btn').text(termName);
                    getProjectsAjax();
                } else {
                    $(this).removeClass('active');
                    let text = $(parent).find('.project-archive__filters-btn').data('label');
                    $(parent).find('.project-archive__filters-btn').text(text);
                    getProjectsAjax();
                }

            });

            function getProjectsAjax() {
                const container = $('.project-archive__container');

                $.ajax({
                    type    : 'GET',
                    url     : menaObject.ajaxurl,
                    dataType: 'html', // add data type
                    data    : {
                        action  : 'get_projects',
                        taxonomy: $('.project-archive__filters-item.active').closest('.project-archive__filters').data('taxonomy'),
                        termId  : $('.project-archive__filters-item.active').data('term-id'),
                    },

                    beforeSend: function () {
                        $(container).addClass('loading');
                    },

                    success: function (response) {
                        if (response) {
                            response = JSON.parse(response);

                            document.querySelector('.project-archive__container').scrollIntoView({
                                behavior: 'smooth',
                                block   : 'start',
                                inline  : 'nearest',
                            });

                            $(container).html(response.html);
                            $(container).removeClass('loading');
                        }
                    },
                });
            }
        }
    };

    mena.main.newsArchive = function () {
        if ($('.news-archive-intro').length > 0) {
            $(document).mouseup(function (e) {
                let filters = $('.news-archive-intro__filters');

                if (!filters.is(e.target) && filters.has(e.target).length === 0) {
                    filters.find('.news-archive-intro__filters-list').removeClass('active');
                }
            });

            $('.news-archive-intro__filters').on('click', function () {
                if (!$(this).find('.news-archive-intro__filters-list').hasClass('active')) {
                    $('.news-archive-intro__filters-list').removeClass('active');
                    $(this).find('.news-archive-intro__filters-list').addClass('active');
                } else {
                    $('.news-archive-intro__filters-list').removeClass('active');
                }
            });

            $('.news-archive-intro__filters-item').on('click', function () {
                let termName = $(this).text();
                let parent = $(this).closest('.project-archive__filters');
                $('body').trigger('click');

                if (!$(this).hasClass('active')) {
                    parent = $('.news-archive-intro__filters-item.active').closest('.news-archive-intro__filters');
                    let text = $(parent).find('.news-archive-intro__filters-btn').data('label');
                    $(parent).find('.news-archive-intro__filters-btn').text(text);

                    $('.news-archive-intro__filters-item').removeClass('active');
                    $(this).addClass('active');
                    $(this).closest('.news-archive-intro__filters').find('.news-archive-intro__filters-btn').text(termName);
                    getNewsAjax();
                } else {
                    $(this).removeClass('active');
                    let text = $(parent).find('.news-archive-intro__filters-btn').data('label');
                    $(parent).find('.news-archive-intro__filters-btn').text(text);
                    getNewsAjax();
                }

            });

            function getNewsAjax() {
                const container = $('.news__cards');

                $.ajax({
                    type    : 'GET',
                    url     : menaObject.ajaxurl,
                    dataType: 'html',
                    data    : {
                        action  : 'mena_get_news_ajax',
                        taxonomy: $('.news-archive-intro__filters-item.active').closest('.news-archive-intro__filters').data('taxonomy'),
                        termId  : $('.news-archive-intro__filters-item.active').data('term-id'),
                    },

                    beforeSend: function () {
                        $(container).addClass('loading');
                    },

                    success: function (response) {
                        if (response) {
                            response = JSON.parse(response);

                            document.querySelector('.news__cards').scrollIntoView({
                                behavior: 'smooth',
                                block   : 'start',
                                inline  : 'nearest',
                            });

                            $(container).html(response.html);
                            $(container).removeClass('loading');
                        }
                    },
                });
            }
        }
    };

    mena.main.stickySocials = function () {
        const socials = $('.single-news__sticky-socials');
        const introV2Height = $('.intro-v2').height();
        const socialsHeight = $('.single-news__sticky-socials-container').height();
        const scroll = introV2Height - socialsHeight;

        $(window).scroll(function () {
            if ($(window).scrollTop() >= scroll) {
                if (!$(socials).hasClass('black')) {
                    $(socials).addClass('black');
                }
            } else {
                if ($(socials).hasClass('black')) {
                    $(socials).removeClass('black');
                }
            }
        });
    };

    mena.main.megaMenu = function () {
        if ($('.mega-menu').length > 0 && window.innerWidth > 1023) {
            $('.js-mega-menu-service a').on('click', function (ev) {
                ev.preventDefault();
                $('.mega-menu').slideToggle(300);
            });

            $(window).resize(function () {
                $('.mega-menu').slideUp(300);
            });
        }
    };

    mena.main.langSwitcher = function () {
        $('.header__lang-switcher .current-lang').on('click', function (e) {
            e.preventDefault();
            $('.header__lang-switcher').toggleClass('active');
        });

        $(document).on('click', function (e) {
            let $target = $(e.target);
            let $langSwitcher = $('.header__lang-switcher');

            console.log($target);

            // Check if the clicked element is outside the lang switcher box
            if (!$target.closest('.header__lang-switcher').length) {
                $langSwitcher.removeClass('active');
            }
        });
    };

    return mena.main.init();

}($));
